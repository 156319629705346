import React from 'react'
import { Col, Icon, Row, Timeline } from 'antd'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import SectionTitle from './SectionTitle'
import Subscribe from './Subscribe'
import {
  SubsectionIcon,
  SubsectionTitle,
  SubsectionSubtitle,
  SubsectionCTAs,
  SubsectionMobileCTAs,
} from './Basic'
import UsersIcon from '../images/users.svg'
import Map from '../images/map-marked-alt-light.svg'

const Card = styled.div`
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0,0,0,.1), 0 10px 22px rgba(0,0,0,.2);
  background-color: #fff;
  user-select: none;
  padding: 20px 20px 20px 50px;
  justify-content: center;
}
`
const UserInsights = () => (
  <Row style={{ marginTop: '2em' }} gutter={20}>
    <SectionTitle
      icon={<UsersIcon viewBox="0 0 40 40" width="70px" height="70px" />}
      color={theme.colors.material.deeppurple.primary}
      title="User Insights"
      subtitle="Get a complete picture of your users and their journeys through your product."
    />
    <Col md={{ span: 11 }}>
      <SubsectionIcon>
        <Map viewBox="0 0 576 512" width="50px" height="50px" />
      </SubsectionIcon>
      <SubsectionTitle>Map all your customers</SubsectionTitle>
      <SubsectionSubtitle>
        See how customers use your product and the feedback they give along the
        way
      </SubsectionSubtitle>
      <SubsectionCTAs>
        <Subscribe />
      </SubsectionCTAs>
    </Col>
    <Col md={{ span: 12, push: 1 }}>
      <Card>
        <Timeline>
          <Timeline.Item>Signed Up</Timeline.Item>
          <Timeline.Item>Account Created</Timeline.Item>
          <Timeline.Item>Workspace Created</Timeline.Item>
          <Timeline.Item>
            <p>
              Gave feedback on <strong>Create workspaces</strong>
            </p>
            <ul>
              <li className="satisfaction satisfaction--neutral">
                <Icon type="meh" style={{ fontSize: '18px' }} /> Neutral
              </li>
              <li className="importance importance--low">
                <Icon type="dislike" style={{ fontSize: '18px' }} /> Not
                Important
              </li>
            </ul>
          </Timeline.Item>
          <Timeline.Item>Funnel Created</Timeline.Item>
          <Timeline.Item>
            <p>
              Gave feedback on <strong>Create funnels</strong>
            </p>
            <ul>
              <li className="satisfaction satisfaction--happy">
                <Icon type="smile" style={{ fontSize: '18px' }} /> Happy
              </li>
              <li className="importance importance--high">
                <Icon type="like" style={{ fontSize: '18px' }} /> Very Important
              </li>
            </ul>
          </Timeline.Item>
        </Timeline>
      </Card>
      <SubsectionMobileCTAs>
        <Subscribe />
      </SubsectionMobileCTAs>
    </Col>
  </Row>
)

export default UserInsights
