import React from 'react'
import { Avatar } from 'antd'
import styled from '@emotion/styled'
import { string } from 'prop-types'
import theme from '../../styles/theme'
import user from '../../images/user.jpg'
import Happy from '../../images/faces/Happy.svg'
import Neutral from '../../images/faces/Neutral.svg'
import Unhappy from '../../images/faces/Unhappy.svg'

const WidgetContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 10px;
  margin-bottom: 10px;
  opacity: 0;
  width: 260px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 10px 22px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 20px;
  user-select: none;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      bottom: -10px;
    }

    to {
      opacity: 1;
      bottom: 10px;
    }
  }

  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  transition: max-height 0.2s ease-out;
`

const SkeletonName = styled.div`
  margin: 12px;
  background: #eee;
  height: 16px;
  width: 160px;
`

const TopicType = styled.p`
  color: ${theme.colors.blue};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
`

const WidgetUser = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const WidgetTitle = styled.p`
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0;
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const IconWrapper = styled.div`
  svg {
    .outline,
    .eyes,
    .mouth {
      fill: ${props =>
        props.isShown ? theme.colors.black : theme.colors.lightGrey};
    }

    .background {
      fill: ${props => (props.isShown && props.color ? props.color : 'white')};
    }
  }
`

const Widget = ({ smiley }) => (
  <WidgetContainer>
    <WidgetUser>
      <Avatar src={user} size="large" />
      <SkeletonName />
    </WidgetUser>
    <TopicType>Feature</TopicType>
    <WidgetTitle>Inviting users</WidgetTitle>
    <p>How happy are you with the invite feature?</p>
    <IconsWrapper>
      <IconWrapper color={theme.colors.green} isShown={smiley === 'happy'}>
        <Happy width="50" height="50" />
      </IconWrapper>
      <IconWrapper color={theme.colors.yellow} isShown={smiley === 'neutral'}>
        <Neutral width="50" height="50" />
      </IconWrapper>
      <IconWrapper color={theme.colors.red} isShown={smiley === 'unhappy'}>
        <Unhappy width="50" height="50" />
      </IconWrapper>
    </IconsWrapper>
  </WidgetContainer>
)

Widget.defaultProps = {
  smiley: null,
}

Widget.propTypes = {
  smiley: string,
}

export default Widget
