import React from 'react'
import { Tabs } from 'antd'
import styled from '@emotion/styled'
import ScreenshotOverlay from './ScreenshotOverlay'
import siftDevicesScreenshot from '../../images/sift-devices-landing.png'
import siftRulesScreenshot from '../../images/sift-rules.png'
import siftDashboardScreenshot from '../../images/sift-dashboard.png'
import siftCompletedScreenshot from '../../images/sift-completed.png'

const { TabPane } = Tabs

const DetailsContainer = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  user-select: none;
  width: 100%;
}
`

const Screenshots = () => (
  <Tabs tabPosition="top">
    <TabPane
      tab="Choose your audience for feedback"
      key="1"
      style={{ padding: '10px 0' }}
    >
      <DetailsContainer>
        <ScreenshotOverlay
          title="Build &rarr; Measure &rarr; Learn"
          styles={{ top: 20, right: 380 }}
          description="You can ask for feedback on your features, new ideas, or even customer problems."
        />
        <ScreenshotOverlay
          title="Set Powerful Rules"
          styles={{ top: 176, right: 0 }}
          description="Define rules to match page visits, tracked events, or user traits."
        />
        <ScreenshotOverlay
          title="Preview Your Audience"
          styles={{ bottom: 20, left: 240 }}
          description="Always have confidence you're talking to the right users."
        />
        <img
          src={siftRulesScreenshot}
          width="1024px"
          alt=""
          style={{ width: '100%' }}
        />
      </DetailsContainer>
    </TabPane>
    <TabPane
      tab="Send surveys inside your app"
      key="2"
      style={{ padding: '10px 0' }}
    >
      <DetailsContainer>
        <ScreenshotOverlay
          title="Feedback In Context"
          styles={{ top: 22, left: 240 }}
          description="Ask for feedback in your app so you get feedback at the perfect time, every time."
        />
        <ScreenshotOverlay
          title="Desktop And Mobile Web"
          styles={{ bottom: 126, right: 110 }}
          description=" Your surveys look great on any device, at any size."
        />
        <img
          src={siftDevicesScreenshot}
          width="1024px"
          alt=""
          style={{ width: '100%' }}
        />
      </DetailsContainer>
    </TabPane>
    <TabPane
      tab="Review all your feedback"
      key="3"
      style={{ padding: '10px 0' }}
    >
      <DetailsContainer>
        <ScreenshotOverlay
          title="User Feedback Reports"
          styles={{ top: 50, left: 360 }}
          description="See all your product feedback and the individual users who gave it."
        />
        <ScreenshotOverlay
          title="Open-Ended Feedback"
          styles={{ bottom: 310, left: 130 }}
          description="Get deep feedback from your users so you can improve your product."
        />
        <ScreenshotOverlay
          title="Importance and Happiness"
          styles={{ bottom: 120, right: 10 }}
          description="Understand what's important to your users, and how happy they are with your solution."
        />
        <img
          src={siftCompletedScreenshot}
          width="1024px"
          alt=""
          style={{ width: '100%' }}
        />
      </DetailsContainer>
    </TabPane>
    <TabPane
      tab="Uncover your biggest priorities"
      key="4"
      style={{ padding: '10px 0' }}
    >
      <DetailsContainer>
        <ScreenshotOverlay
          title="See the Big Picture"
          styles={{ top: 20, left: 380 }}
          description="Understand user perception of your whole product – in detail."
        />
        <ScreenshotOverlay
          title="Find Opportunities to Improve"
          styles={{ bottom: 120, right: 20 }}
          description="We calculate an opportunity score to help you make positive changes for users."
          pointerStyles={{ top: -8, bottom: 'unset' }}
        />
        <img
          src={siftDashboardScreenshot}
          width="1024px"
          alt=""
          style={{ width: '100%' }}
        />
      </DetailsContainer>
    </TabPane>
  </Tabs>
)

export default Screenshots
