import React from 'react'
import { Icon } from 'antd'
import styled from '@emotion/styled'
import { bool, string } from 'prop-types'
import theme from '../../styles/theme'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  user-select: none;
  width: 100%;
`

const Trigger = ({ matched, triggerName, triggerDescription }) => (
  <Wrapper>
    <div
      style={{
        background: 'white',
        border: '2px solid',
        borderColor: matched
          ? theme.colors.material.green.primary
          : theme.colors.lightGrey,
        borderRadius: 5,
        color: matched
          ? theme.colors.material.green['900']
          : theme.colors.black,
        padding: '10px 20px',
        margin: '0',
        width: '320px',
      }}
    >
      <div>
        <span
          style={{
            fontSize: 13,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {triggerName}
        </span>
      </div>
      {`${triggerName} ${triggerDescription}`}{' '}
      {matched && (
        <Icon
          type="check"
          style={{
            color: theme.colors.material.green.primary,
            marginLeft: 10,
            verticalAlign: 'text-bottom',
          }}
        />
      )}
    </div>
  </Wrapper>
)

Trigger.propTypes = {
  matched: bool.isRequired,
  triggerName: string.isRequired,
  triggerDescription: string.isRequired,
}

export default Trigger
