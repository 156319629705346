import React from 'react'

import { Col, Row } from 'antd'
import theme from '../styles/theme'
import {
  SubsectionIcon,
  SubsectionTitle,
  SubsectionSubtitle,
  SubsectionCTAs,
} from './Basic'
import SectionTitle from './SectionTitle'
import Subscribe from './Subscribe'
import FeedbackDemo from './ProductFeedback/FeedbackDemo'
import Screenshots from './ProductFeedback/Screenshots'
import FeedbackIcon from '../images/feedback.svg'
import Target from '../images/bullseye-pointer-light.svg'

const ProductFeedback = () => {
  return (
    <>
      <Row style={{ margin: '2em 0' }} gutter={20}>
        <SectionTitle
          color={theme.colors.material.blue.primary}
          icon={<FeedbackIcon viewBox="0 0 40 40" width="70px" height="70px" />}
          title="Product Feedback"
          subtitle="Ask for feedback inside your product."
        />
        <Col md={{ span: 11 }}>
          <SubsectionIcon>
            <Target viewBox="0 0 496 496" width="50px" height="50px" />
          </SubsectionIcon>
          <SubsectionTitle>Targeted feedback at scale</SubsectionTitle>
          <SubsectionSubtitle>
            Segment your customers and trigger in-app feedback based on who they
            are and what they&apos;re doing
          </SubsectionSubtitle>
          <SubsectionCTAs>
            <Subscribe />
          </SubsectionCTAs>
        </Col>
        <Col md={{ span: 12, push: 1 }}>
          <FeedbackDemo />
        </Col>
      </Row>
      <Row style={{ margin: '4em 0' }}>
        <Col span={24}>
          <Screenshots />
        </Col>
      </Row>
    </>
  )
}

export default ProductFeedback
