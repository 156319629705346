import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/Container'
import ProductFeedback from '../components/ProductFeedback'
import UserInsights from '../components/UserInsights'
import Testimonial from '../components/Testimonial'
import Subscribe from '../components/Subscribe'
import SubscribeSection from '../components/SubscribeSection'
import theme from '../styles/theme'
import HeroImage from '../images/profile_analyze.svg'
import { practicePortuguese, pythonMorsels } from '../data/testimonials'
import 'antd/dist/antd.css'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: skewY(-8deg);
  transform-origin: 0;
  background: linear-gradient(150deg, #e8eaf6 15%, #e3f2fd 70%, #e1f5fe 90%);
  top: 0;
`

const Bolded = styled.span`
  font-weight: 600;
`

const Highlighted = styled.span`
  color: ${theme.colors.blue};
`

const Hero = styled.div`
  display: flex;
  height: 550px;
  align-items: center;
`

const HeroInner = styled.div`
  display: flex;
`

const HeroContent = styled.div`
  flex: 55%;

  @media (max-width: 669px) {
    flex: 100%;
  }
`

const HeroImageContainer = styled.div`
  position: absolute;
  right: 0;
  width: 40%;
  text-align: right;

  @media (max-width: 669px) {
    display: none;
  }
`

const Spacer = styled.div`
  flex: 40%;

  @media (max-width: 669px) {
    display: none;
  }
`

const Section = styled.section`
  margin-bottom: 6em;
`

const IndexPage = () => (
  <Layout color="blue">
    <SEO title="Sift: Find and keep product market fit" overrideTitle />
    <header style={{ position: 'relative' }}>
      <Background />
      <Hero>
        <Container>
          <HeroInner>
            <HeroContent>
              <h1>
                <Bolded>
                  Find <em>and keep</em>
                </Bolded>{' '}
                <br />
                <Highlighted>product market fit.</Highlighted>
              </h1>
              <p
                style={{
                  fontSize: '1.3em',
                  marginTop: '2em',
                }}
              >
                Sift helps you get feedback on all your features, ideas, and
                unsolved customer problems – right inside your product.
              </p>
              <div style={{ marginTop: '2em' }}>
                <Subscribe />
              </div>
            </HeroContent>
            <Spacer />
            <HeroImageContainer>
              <HeroImage style={{ maxWidth: 340 }} />
            </HeroImageContainer>
          </HeroInner>
        </Container>
      </Hero>
    </header>
    <Section id="product-feedback">
      <Container>
        <ProductFeedback />
      </Container>
    </Section>
    <Section>
      <Container>
        <Testimonial testimonial={practicePortuguese} />
      </Container>
    </Section>
    <Section id="user-insights">
      <Container>
        <UserInsights />
      </Container>
    </Section>
    <Section>
      <Container>
        <Testimonial testimonial={pythonMorsels} />
      </Container>
    </Section>
    <Section>
      <div id="request-invite">
        <SubscribeSection />
      </div>
    </Section>
  </Layout>
)

export default IndexPage
