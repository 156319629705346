import practicePortugueseBg from '../images/practice-portuguese-bg.jpg'
import practicePortugueseLogo from '../images/practice-portuguese.png'
import joelImage from '../images/joel-rendall.jpg'
import pythonMorselsBg from '../images/python-morsels-bg.jpg'
import pythonMorselsLogo from '../images/python-morsels.png'
import treyImage from '../images/trey-hunner.jpg'

const practicePortuguese = {
  background: practicePortugueseBg,
  logo: practicePortugueseLogo,
  logoWidth: '157',
  personImage: joelImage,
  personName: 'Joel Rendall',
  personBio: 'Founder of Practice Portuguese',
  quote:
    'Being able to collect feedback based on customer attributes and their interactions with our platform is huge. It also shows our members that we respect their time (compared to just sending a general survey) – and that makes our response rate higher as well: over 65%!',
}

const pythonMorsels = {
  background: pythonMorselsBg,
  logo: pythonMorselsLogo,
  logoWidth: '227',
  personImage: treyImage,
  personName: 'Trey Hunner',
  personBio: 'Founder of Python Morsels',
  quote:
    `I was impressed by how many responses I got to the first question I put in Sift. Well over half of my customers who saw it responded and many of them also left helpful free-form feedback. One of my users even identified a long-standing bug that hadn't been reported.`,
}

export { practicePortuguese, pythonMorsels }
