import React, { useRef, useState, useEffect, useCallback } from 'react'
import { InView } from 'react-intersection-observer'
import { useIdle } from 'react-use'
import { useTransition, animated } from 'react-spring'
import theme from '../../styles/theme'
import Browser from '../Browser'
import Trigger from './Trigger'
import Widget from './Widget'

const smiles = ['happy', 'neutral', 'unhappy']

const FeedbackDemo = () => {
  const ref = useRef([])

  const isIdle = useIdle(60e3)
  const [inView, setInView] = useState(false)
  const [firstMatched, setFirstMatched] = useState(false)
  const [secondMatched, setSecondMatched] = useState(false)
  const [isWidgetVisible, setIsWidgetVisible] = useState(false)
  const [smiley, setSmiley] = useState(null)
  const [items, setItems] = useState([])

  const transitions = useTransition(items, null, {
    from: {
      opacity: 0,
      marginLeft: 50,
      color: theme.colors.black,
    },
    enter: [
      {
        opacity: 1,
        marginLeft: -20,
      },
    ],
    leave: [{ opacity: 0, marginLeft: 50 }],
  })

  const unsetTimeout = () => {
    if (ref && ref.current) ref.current.map(clearTimeout)
  }

  const reset = useCallback(() => {
    unsetTimeout()
    ref.current = []
    setItems([])
    setFirstMatched(false)
    setSecondMatched(false)
    setIsWidgetVisible(false)
    setSmiley(null)

    ref.current.push(
      setTimeout(() => {
        setItems(['trait'])
      }, 1000)
    )
    ref.current.push(
      setTimeout(() => {
        setItems(['trait', 'event'])
      }, 2000)
    )
    ref.current.push(
      setTimeout(() => {
        setFirstMatched(true)
      }, 2750)
    )
    ref.current.push(
      setTimeout(() => {
        setSecondMatched(true)
      }, 3500)
    )
    ref.current.push(
      setTimeout(() => {
        setIsWidgetVisible(true)
      }, 4000)
    )
    ref.current.push(
      setTimeout(() => {
        setSmiley(smiles[Math.floor(Math.random() * smiles.length)])
      }, 5000)
    )
    ref.current.push(
      setTimeout(() => {
        reset()
      }, 9000)
    )
  }, [])

  useEffect(() => {
    if (inView && !isIdle) {
      reset()
    }

    if (!inView || isIdle) {
      unsetTimeout()
    }

    return () => {
      unsetTimeout()
    }
  }, [inView, isIdle, reset])

  return (
    <InView onChange={inview => setInView(inview)}>
      <div style={{ position: 'absolute', left: 0, top: 46 }}>
        {transitions.map(({ item, props: { ...rest }, key }) => (
          <animated.div
            className="transitions-item"
            key={key}
            style={rest}
            onClick={reset}
          >
            <animated.div style={{ overflow: 'hidden' }}>
              <>
                {item === 'trait' && (
                  <Trigger
                    matched={firstMatched}
                    triggerName="Subscription Plan"
                    triggerDescription="is Team (monthly)"
                  />
                )}
                {item === 'event' && (
                  <Trigger
                    matched={secondMatched}
                    triggerName="Invited User"
                    triggerDescription="count is 3"
                  />
                )}
              </>
            </animated.div>
          </animated.div>
        ))}
      </div>
      <Browser>{isWidgetVisible && <Widget smiley={smiley} />}</Browser>
    </InView>
  )
}

export default FeedbackDemo
