import React from 'react'
import styled from '@emotion/styled'

const IconContainer = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  margin: 2em 0;
  flex-direction: column;
  text-align: center;
`

const SectionTitleContent = styled.div`
  margin: 1em 0;
  width: 100%;

  h3 {
    color: ${props => props.color};
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    opacity: 0.7;
    font-size: 1.2em;
  }
`

const SectionTitle = ({ color, icon, title, subtitle }) => (
  <Wrapper>
    <div>
      <IconContainer>{icon}</IconContainer>
    </div>
    <SectionTitleContent color={color}>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </SectionTitleContent>
  </Wrapper>
)

export default SectionTitle
