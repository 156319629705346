import React from 'react'
import styled from '@emotion/styled'
import { shape, string } from 'prop-types'
import theme from '../../styles/theme'

const ScreenshotOverlayWrapper = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dadfe2;
  box-shadow: 0 0 1px rgba(95, 108, 114, 0.09),
    0 2px 18px rgba(95, 108, 114, 0.18);
  padding: 1em;
  position: absolute;
  width: 300px;

  p {
    margin-bottom: 5px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`

const ScreenshotOverlayPointer = styled.div`
  background: ${theme.colors.blue};
  border-radius: 50%;
  bottom: -8px;
  height: 16px;
  left: -8px;
  padding: 3px;
  position: absolute;
  width: 16px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 50%;
    animation: antStatusProcessing 2s infinite ease-in-out;
    content: '';
  }
`

const ScreenshotOverlay = ({ description, pointerStyles, styles, title }) => {
  return (
    <ScreenshotOverlayWrapper style={styles}>
      <p>
        <strong>{title}</strong>
      </p>
      <p>{description}</p>
      <ScreenshotOverlayPointer style={pointerStyles} />
    </ScreenshotOverlayWrapper>
  )
}

ScreenshotOverlay.defaultProps = {
  pointerStyles: {},
}

ScreenshotOverlay.propTypes = {
  description: string.isRequired,
  pointerStyles: shape({}),
  styles: shape({}).isRequired,
  title: string.isRequired,
}

export default ScreenshotOverlay
