import React from 'react'
import styled from '@emotion/styled'

const BrowserContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0,0,0,.1), 0 10px 22px rgba(0,0,0,.2);
  display: block;
  margin: 0 auto;
  overflow: hidden;
  user-select: none;
  width: 100%;
}
`

const BrowserBar = styled.div`
  background: #333333;
  padding: 12px;
`

const BrowserButtons = styled.div`
  background: #ff5f56;
  box-shadow: 20px 0 0 #ffbd2e, 40px 0 0 #27c93f;
  border-radius: 50%;
  height: 12px;
  width: 12px;
`

const BrowserContent = styled.div`
  height: calc(100% - 36px);
  background-size: cover;
  background-position: 0 0;
  min-height: 420px;
  padding: 20px;
  position: relative;
`

const Browser = ({ children }) => (
  <BrowserContainer>
    <BrowserBar>
      <BrowserButtons />
    </BrowserBar>
    <BrowserContent>{children}</BrowserContent>
  </BrowserContainer>
)

export default Browser
