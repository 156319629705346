import React from 'react'
import styled from '@emotion/styled'
import { Avatar } from 'antd'
import theme from '../styles/theme'

const TestimonialInner = styled.div`
  display: block;
  border-radius: 4px;
  max-height: 100%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 10px 22px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 250px;

  @media (max-width: 767px) {
    height: 100%;
  }
`

const TestimonialImageWrap = styled.div`
  transform: skewX(-12deg);
  position: absolute;
  overflow: hidden;
  padding-left: 20px;
  margin-left: -20px;
  left: -500px;
  right: calc(50% + 100px);
  height: 100%;

  @media (max-width: 767px) {
    height: 250px;
    left: 0;
    right: 0;
    margin-left: 0;
    padding-left: 0;
    transform: none;
  }
`

const TestimonialImage = styled.li`
  background-image: ${props => `url(${props.url})`};
  height: 250px;
  transform: skewX(12deg);
  margin-right: -22px;
  left: 480px;
  position: absolute;
  right: -50px;
  top: 0;
  bottom: 0;
  background-position: 50%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    transform: none;
    left: 0;
    right: 0;
  }
`

const TestimonialContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1em 2em 1em 50%;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    padding: calc(250px + 1em) 2em 1em calc(2em + 30px);
  }

  @media (max-width: 479px) {
    padding-right: 10px;
    padding-left: 40px;
  }
`

const TestimonialQuote = styled.p`
  font-size: 16px;

  &:before {
    content: open-quote;
    position: absolute;
    font-size: 50px;
    width: 20px;
    margin-left: -30px;
    margin-top: -20px;
  }

  &:after {
    content: close-quote;
    font-size: 0;
  }
`

const TestimonialPerson = styled.div`
  display: flex;
`

const TestimonialAvatar = styled(Avatar)`
  align-self: flex-start;
  width: 50px;

  @media (max-width: 350px) {
    &&& {
      display: none;
    }
  }
`

const TestimonialPersonDetails = styled.div`
  margin-left: 10px;

  @media (max-width: 350px) {
    margin: 0;
  }
`

const Testimonials = ({ testimonial }) => {
  const {
    background,
    logo,
    logoWidth,
    personBio,
    personImage,
    personName,
    quote,
  } = testimonial

  return (
    <TestimonialInner>
      <TestimonialImageWrap>
        <TestimonialImage url={background}>
          <img src={logo} width={logoWidth} alt="" />
        </TestimonialImage>
      </TestimonialImageWrap>
      <TestimonialContent>
        <div>
          <TestimonialQuote>{quote}</TestimonialQuote>
          <TestimonialPerson>
            <TestimonialAvatar size={50} src={personImage} />
            <TestimonialPersonDetails>
              <div>
                <strong>{personName}</strong>
              </div>
              <div style={{ color: theme.colors.grey }}>{personBio}</div>
            </TestimonialPersonDetails>
          </TestimonialPerson>
        </div>
      </TestimonialContent>
    </TestimonialInner>
  )
}

export default Testimonials
